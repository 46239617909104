.Grid {
    --grid-column: 12;
    --grid-column-gap: 24px;
    width: 100%;
    padding: 0 130px;
    display: grid;
    grid-template-columns: repeat(var(--grid-column), 1fr);
    grid-template-rows: auto;
    grid-column-gap: var(--grid-column-gap);
    /*padding: 0 var(--grid-column-gap);*/
}


@media screen and (max-width: 1919px) {
    .Grid {
        padding: 0 78px;
    }
}

@media screen and (max-width: 1439px) {
    .Grid {
        padding: 0 24px;
    }
}

@media screen and (max-width: 1023px) {
    .Grid {
        --grid-column: 8;
    }
}

@media screen and (max-width: 719px) {
    .Grid {
        --grid-column: 4;
        --grid-gap: 16;
    }
}

