.PaddingContainer {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 30px;
}

@media screen and (max-width: 1919px){
    .PaddingContainer {

    }
}

@media screen and (max-width: 1440px){
    .PaddingContainer {

    }
}

@media screen and (max-width: 719px){
    .PaddingContainer {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
}