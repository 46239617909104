.TextArea {
    background: #FFFFFF;
    border: none;
    font: 400 24px var(--font-Roboto);
    resize: none;
    width: 100%;
    --webkit-appearance: none;
    border-radius: 5px;
    border: 1px solid black;
}

@media screen and (max-width: 1439px) {
    .TextArea {
        font-size: 18px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1023px) {
    .TextArea {
        border-radius: 15px;
        font-size: 12px;
        line-height: 14px;
        padding: 15px 25px;
    }
}
