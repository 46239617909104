@import "animation.css";
@import "atom.layout.css";
@import "atom.indents.css";
@import "vars.css";
@import "grid.css";
@import "reset.css";

* {
    font-family: 'Rubik', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

/* ScrollBar */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background: rgba(var(--color-gray), 0.4);
    border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--color-gray), 0.6);
}
