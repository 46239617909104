button.button--reset {
    outline: none;
    border: none;
    background-color: transparent;
}

span.clip--text {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
