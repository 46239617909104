html {
    overflow-x: hidden;
}

.App {
    
}

li {
    list-style: none;
}