.CompanyItem {

}

.CompanyItem > h5 {
    font: 500 14px var(--font-CeraPro);
    color: rgb(var(--color-basic));
    padding: 8px 0;
    word-break: break-all;
}

.CompanyItem > p {
    font: 400 10px var(--font-CeraPro);
    margin-bottom: 6px;
    color: rgb(var(--color-text));
}

.CompanyItem > p:last-child {
    margin-bottom: 0;
}


.CompanyItem__inactive {
    font: 400 9px var(--font-CeraPro);
    color: rgb(var(--color-red));
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;
    column-gap: 6px;
}

.CompanyItem__inactive::before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    background: center /contain no-repeat url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.985104 0.798682C0.839335 0.64625 0.573454 0.621536 0.391243 0.743482C0.209032 0.865427 0.179489 1.08785 0.325258 1.24028L8.24753 9.52465C8.3933 9.67708 8.65918 9.7018 8.84139 9.57985C9.02361 9.45791 9.05315 9.23548 8.90738 9.08305L7.51066 7.62249V5.3361H7.92574C8.15499 5.3361 8.34084 5.18187 8.34084 4.99162C8.34084 4.80138 8.15499 4.64715 7.92574 4.64715H4.66536L4.66535 4.64713H7.92575L4.36637 1.48235C4.19811 1.33275 3.91072 1.33714 3.74922 1.49179L2.65232 2.5421L0.985104 0.798682ZM2.72055 4.64713L1.62674 3.52413L0.453928 4.64713H2.72055ZM7.08243 9.12544L2.72057 4.64715H0.45392C0.224666 4.64715 0.0388184 4.80138 0.0388184 4.99162C0.0388184 5.18187 0.224666 5.3361 0.45392 5.3361H0.869038V8.43649H0.45392C0.224666 8.43649 0.0388184 8.59072 0.0388184 8.78097C0.0388184 8.97122 0.224666 9.12544 0.45392 9.12544H7.08243Z' fill='%23F60101'/%3E%3C/svg%3E%0A");
}