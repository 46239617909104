@keyframes state-focus-animation {
    from {
        box-shadow: 0 0 2px 8px rgba(var(--color-basic-2), .2);
    }
    to {
        box-shadow: 0 0 0 4px rgba(var(--color-basic-2), .4);
    }
}


/* class animation */
.waiting-dots-animation {
    position: relative;
}

.waiting-dots-animation::after {
    content: "";
    position: absolute;
    animation: waiting-dots-animation infinite 2s;
}

@keyframes waiting-dots-animation {
    0% {
        content: '.'
    }
    50% {
        content: '..'
    }
    100% {
        content: '...'
    }
}

.loading-rotate-animate {
    animation: loading-rotate-animate infinite 1s;
}

@keyframes loading-rotate-animate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
