.Checkbox {
    --checkbox-icon-check: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49373 0.758435C8.83831 0.413855 9.39698 0.413855 9.74156 0.758435C10.0861 1.10302 10.0861 1.66169 9.74156 2.00627L4.44745 7.30039C4.10287 7.64497 3.54419 7.64497 3.19961 7.30039L0.258435 4.35921C-0.0861451 4.01463 -0.0861451 3.45596 0.258435 3.11138C0.603015 2.7668 1.16169 2.7668 1.50627 3.11138L3.82353 5.42863L8.49373 0.758435Z' fill='white'/%3E%3C/svg%3E%0A");
    --checkbox-size: 16px;

    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns:20px auto;
    grid-template-rows: auto;
    align-content: center;
    position: relative;
}

.Checkbox > input {
    grid-column: 1/2;
    grid-row: 1/-1;
    opacity: 0;
    appearance: none;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    position: absolute;
    cursor: pointer;
    appearance: none;
    --webkit-appearance: none;
}

.Checkbox > span {
    display: block;
    grid-column: 1/2;
    grid-row: 1/-1;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    border-radius: 4px;
    border: 1px solid rgba(var(--color-basic), 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    transition: background-color ease .25s;
    will-change: background-color;
}

.Checkbox > label {
    grid-column: 2/-1;
    grid-row: 1/-1;
    font: 300 14px var(--font-Roboto);
    margin-left: 10px;
}

/* Checkbox focus */
.Checkbox > input:focus + span {
    box-shadow: var(--state-focus);
    animation: var(--state-focus-animation);
}

/* Checkbox checked */
.Checkbox > input:checked + span {
    background-image: var(--checkbox-icon-check);
    background-color: rgba(var(--color-basic), 1);
    border: 1px solid rgba(var(--color-basic), 1);
}

/* Checkbox disabled */
.Checkbox > input:disabled,
.Checkbox > input:disabled + span {
    border: 1px solid var(--state-disabled);
    background-color: var(--state-disabled);
    cursor: not-allowed;
}